import { ThemeProvider } from 'styled-components';

import { useStore, setState } from 'store/store';

import { themeOptions } from 'store/lists';

import { useTranslation } from 'react-i18next';
import { useEffect, Suspense, lazy } from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';

import ReactGA from 'react-ga4';

const FallbackPlaceHolder = lazy(() => import('./FallbackPlaceHolder'));

const Home = lazy(() => import('./Home'));
const PageNotFound = lazy(() => import('./PageNotFound'));
const Pricing = lazy(() => import('./Pricing'));
const SubscriptionCheckout = lazy(() => import('./SubscriptionCheckout'));
const SignUp = lazy(() => import('./SignUp'));
const SignIn = lazy(() => import('./SignIn'));
const AuthSuccess = lazy(() => import('./AuthSuccess'));

const Contact = lazy(() => import('./Contact'));
const Configurator = lazy(() => import('./Configurator'));

const TermsOfUsePage = lazy(() => import('./TermsOfUse'));
const PrivacyPolicyPage = lazy(() => import('./PrivacPolicy'));
const FaqPage = lazy(() => import('./Faq'));
const DocsPage = lazy(() => import('./Docs'));
const AboutUsPage = lazy(() => import('./AboutUs'));

const ForgotPasswordPage = lazy(() => import('./ForgotPassword'));
const ActivateAccount = lazy(() => import('./ActivateAccount'));

const Files = lazy(() => import('./Files'));
const ProjectCreatorPage = lazy(() => import('./ProjectCreator'));

const StoreFrontProductsPage = lazy(() => import('./StorefrontProducts'));
const StorefrontSalesPage = lazy(() => import('./StorefrontSales'));
const StorefrontGraphicLibraryPage = lazy(() =>
  import('./StorefrontGraphicLibrary')
);
const StorefrontProjectLibraryPage = lazy(() =>
  import('./StorefrontProjectLibrary')
);
const StorefrontSettingsPage = lazy(() => import('./StorefrontSettings'));

const AppAdminPanelPage = lazy(() => import('./AppAdminPanel'));
const YourAccountPage = lazy(() => import('./YourAccount'));
const AppSelerPanelPage = lazy(() => import('./AppSellerPanel'));
const SubscriptionManagementPage = lazy(() =>
  import('./SubscriptionManagement')
);

const PageTitle = ({ title }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [location, title]);

  return null;
};

export const setupAnalytics = () => {
  if (process.env.REACT_APP_MODE === 'production') {
    const allowFullTracking =
      localStorage.getItem('cookieAnalytics') === 'true';

    if (allowFullTracking) {
      ReactGA.initialize(process.env.REACT_APP_ANALYTICS_KEY, {});
    } else {
      ReactGA.initialize(process.env.REACT_APP_ANALYTICS_KEY, {
        gaOptions: {
          storage: 'none', // Nie zapisuj plików cookies
          anonymizeIp: true, // Maskowanie IP
          clientId: null, // Bez unikalnego ID użytkownika
        },
      });
    }
  }
};

setupAnalytics();

export const analyticsPageView = (location) => {
  // Wysyłamy zdarzenie pageview przy każdej zmianie lokalizacji
  if (process.env.REACT_APP_MODE === 'production') {
    const allowFullTracking =
      localStorage.getItem('cookieAnalytics') === 'true';
    if (allowFullTracking) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
        title: document.title,
      });

      console.log(document.title);
    } else {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
        title: document.title,
        anonymizeIp: true,
      });
    }
  }
};

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Wysyłamy zdarzenie pageview przy każdej zmianie lokalizacji
    analyticsPageView(location);
  }, [location]);

  return null;
};

function App() {
  const { i18n } = useTranslation();
  const [themeId] = useStore((s) => [s.themeId]);

  useEffect(() => {
    const storedThemeId = localStorage.getItem('themeId');
    if (storedThemeId) {
      setState({ themeId: storedThemeId });
    }
  }, []);

  return (
    <ThemeProvider theme={themeOptions[themeId].theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Alter Product" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Alter Product" />
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/pricing"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Pricing" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Pricing" />
                <Pricing />
              </Suspense>
            }
          />
          <Route
            path="/subscription-checkout/:idProduct"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Subscription Checkout" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Subscription Checkout" />
                <SubscriptionCheckout />
              </Suspense>
            }
          />
          <Route
            path="/contact"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Contact" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Contact" />
                <Contact />
              </Suspense>
            }
          />
          <Route
            path="/register"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Register" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Register" />
                <SignUp />
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Forgot Password" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Forgot Password" />
                <ForgotPasswordPage />
              </Suspense>
            }
          />
          <Route
            path="/forgot-password/:token_id"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Forgot Password" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Forgot Password" />
                <ForgotPasswordPage />
              </Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Forgot Password" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Login" />
                <SignIn />
              </Suspense>
            }
          />
          <Route
            path="/auth-success"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Auth page" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Auth page" />
                <AuthSuccess />
              </Suspense>
            }
          />
          <Route
            path="/activate-account"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Activate Account" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Activate Account" />
                <ActivateAccount />
              </Suspense>
            }
          />
          <Route
            path="/activate-account/:activation_id"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Activate Account" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Activate Account" />
                <ActivateAccount />
              </Suspense>
            }
          />
          <Route
            path="/app/design"
            element={
              <Suspense fallback={<PageTitle title="Design" />}>
                <PageTitle title="Design" />
                <Configurator />
              </Suspense>
            }
          />
          <Route
            path="/app/design/:design_id"
            element={
              <Suspense fallback={<PageTitle title="Design" />}>
                <PageTitle title="Design" />
                <Configurator />
              </Suspense>
            }
          />
          <Route
            path="/terms-of-use"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Terms of use" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Terms of use" />
                <TermsOfUsePage />
              </Suspense>
            }
          />

          <Route
            path="/privacy-policy"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Privacy Policy" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Privacy Policy" />
                <PrivacyPolicyPage />
              </Suspense>
            }
          />
          <Route
            path="/faq"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="FAQ" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="FAQ" />
                <FaqPage />
              </Suspense>
            }
          />
          <Route
            path="/docs"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="Docs" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="Docs" />
                <DocsPage />
              </Suspense>
            }
          />
          <Route
            path="/about-us"
            element={
              <Suspense
                fallback={
                  <>
                    <PageTitle title="About us" />
                    <FallbackPlaceHolder />
                  </>
                }
              >
                <PageTitle title="About us" />
                <AboutUsPage />
              </Suspense>
            }
          />

          <Route path="/app" element={<Navigate to="/app/files" replace />} />

          <Route
            path="/app/files"
            element={
              <Suspense fallback={<PageTitle title="Files" />}>
                <PageTitle title="Files" />
                <Files />
              </Suspense>
            }
          />
          <Route
            path="/app/project-creator"
            element={
              <Suspense fallback={<PageTitle title="Project Creator" />}>
                <PageTitle title="Project Creator" />
                <ProjectCreatorPage />
              </Suspense>
            }
          />
          <Route
            path="/app/storefront-products"
            element={
              <Suspense fallback={<PageTitle title="Storefront Products" />}>
                <PageTitle title="Storefront Products" />
                <StoreFrontProductsPage />
              </Suspense>
            }
          />
          <Route
            path="/app/storefront-sales"
            element={
              <Suspense fallback={<PageTitle title="Storefront Sales" />}>
                <PageTitle title="Storefront Sales" />
                <StorefrontSalesPage />
              </Suspense>
            }
          />
          <Route
            path="/app/storefront-graphic-library"
            element={
              <Suspense
                fallback={<PageTitle title="Storefront Graphic Library" />}
              >
                <PageTitle title="Storefront Graphic Library" />
                <StorefrontGraphicLibraryPage />
              </Suspense>
            }
          />
          <Route
            path="/app/storefront-project-library"
            element={
              <Suspense
                fallback={<PageTitle title="storefront Project Library" />}
              >
                <PageTitle title="storefront Project Library" />
                <StorefrontProjectLibraryPage />
              </Suspense>
            }
          />
          <Route
            path="/app/storefront-settings"
            element={
              <Suspense fallback={<PageTitle title="Storefront Settings" />}>
                <PageTitle title="Storefront Settings" />
                <StorefrontSettingsPage />
              </Suspense>
            }
          />
          <Route
            path="/app/your-account"
            element={
              <Suspense fallback={<PageTitle title="Account Settings" />}>
                <PageTitle title="Account Settings" />
                <YourAccountPage />
              </Suspense>
            }
          />
          <Route
            path="/app/subscription-management"
            element={
              <Suspense
                fallback={<PageTitle title="Subscription Management" />}
              >
                <PageTitle title="Subscription Management" />
                <SubscriptionManagementPage />
              </Suspense>
            }
          />
          <Route
            path="/app/app-seller-panel"
            element={
              <Suspense fallback={<PageTitle title="App Distributor Panel" />}>
                <PageTitle title="App Distributor Panel" />
                <AppSelerPanelPage />
              </Suspense>
            }
          />
          <Route
            path="/app/app-admin-panel"
            element={
              <Suspense fallback={<PageTitle title="App Admin Panel" />}>
                <PageTitle title="App Admin Panel" />
                <AppAdminPanelPage />
              </Suspense>
            }
          />

          <Route
            path="*"
            element={
              <Suspense fallback={<PageTitle title="Error 404" />}>
                <PageTitle title="Error 404" />
                <PageNotFound />
              </Suspense>
            }
          />
        </Routes>
        <Analytics />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
